import React, { useState, useEffect } from 'react';

const PageContent = ({ pageId }) => {
  const [pageContent, setPageContent] = useState('');
  const [token, setToken] = useState('');

  // Function to obtain and set the JWT token, e.g., after user login
  const handleWordpressLogin = async () => {
    // Implement your login logic to obtain the JWT token
    const response = await fetch('https://blog.posbill.com/wp-json/jwt-auth/v1/token?username=admin&password=idvfinke', {
      method: 'POST',
      // Include credentials, headers, and body as needed
    });
    const data = await response.json();
    setToken(data.token);
  };
handleWordpressLogin();
  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await fetch(`https://blog.posbill.com/wp-json/wp/v2/pages/${pageId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          console.error('Unauthorized: Please provide a valid JWT token for authorization.');
          return;
        }

        const data = await response.json();
        setPageContent(data.content.rendered);
      } catch (error) {
        console.error('Error fetching page content:', error);
      }
    };

    fetchPageContent();
  }, [pageId, token]);

  return (
    <div>
      <h2>Page Content</h2>
      <div dangerouslySetInnerHTML={{ __html: pageContent }} />
    </div>
  );
};

export default PageContent;

import React, { useState } from 'react';
import Modal from 'react-modal';
import RegistrationForm from '../Login/RegistrationForm'; // Import your LoginModal component
import { useNavigate } from 'react-router-dom';

import '../../App.css';

function HomePage() {
  const navigate = useNavigate();

  // Move the useState for 'products' inside the component
  const [products, setProducts] = useState([
    { id: 1, name: 'Property 1', price: 200, imageUrl: '/0.png', availableUnits: 10 },
    { id: 2, name: 'Property 2', price: 300, imageUrl: '/rt.png', availableUnits: 0 },
    { id: 3, name: 'Property 3', price: 200, imageUrl: '/0.png', availableUnits: 5 },
    { id: 4, name: 'Property 4', price: 300, imageUrl: '/rt.png', availableUnits: 5 },
  ]);

  const handleOnclick = () => {
    navigate('/mint', 'homePage');
  };

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
  };

  const handleLogin = () => {
    // Handle login logic here
    // You can also close the modal after successful login
    closeLoginModal();
  };
  
  const gotoShop = (mintId) => {
    navigate('/shop', mintId);
  };

const [displayedProducts, setDisplayedProducts] = useState(3); // Initially display 3 properties

  const showMoreProperties = () => {
    // You can update this logic based on your specific requirements
    // For now, let's assume you want to redirect to the '/shop' page
    navigate('/shop');
  };

  // Function to navigate to property details
const redirectToDetails = (productId) => {
  navigate(`/property/${productId}`);
};
const sortedProducts = [...products].sort((a, b) => b.availableUnits - a.availableUnits);


  return (
    <div>
      <header className="text-center mt-5">
        <h1>Welcome to BlockEstateRealty (This page is not an official public website!)</h1>
        <p className="lead">
          Explore a new dimension of real estate through blockchain technology and NFTs.
        </p>
      </header>

    <section className="bg-light mt-5 p-5">
      <h3> Start investing in Properties now</h3>
      <p> Earn passive income and be part of the real estate market with the chosen amount of capital.</p>
        <div className="form-group">
          <input
            type="text"
            className="custom-input"
            id="inputField"
            placeholder="Enter your Email"
          />
          <button type="submit" className="btn btn-primary" onClick={openLoginModal}>
            Start now
          </button>
        </div>

 
      {isLoginModalOpen && (
        <Modal
      isOpen={true} // You can handle the modal state as needed
      onRequestClose={closeLoginModal}
      contentLabel="Registration Modal"
    >
<button onClick={closeLoginModal}>X</button>
        <RegistrationForm
        onClose={closeLoginModal} // Pass a callback to close the modal
        onLogin={handleLogin} // Pass a callback for login logic
        email='sven@posbill.com'
        />
        </Modal>
      )}
  </section>
    
  <section className="container mt-5">
    <div className="row">
        <div className="col-md-6">
          <h2>Welcome to BlockEstateRealty, where innovation meets property ownership in the digital age!</h2>
          <p>🏠 What We Offer: Redefining Property Ownership with NFTs</p>
          <p>Unlock the future of real estate with BlockEstateRealty, your gateway to a groundbreaking fusion of blockchain technology and accuering Real Restate properties. Our platform offers a revolutionary and seamless experience for individuals eager to explore, acquire, and trade real estate assets.</p>
          <p>Why Choose BlockEstateRealty?</p>
          <p>✨ Blockchain Power: Embrace the security and transparency of blockchain technology. Every transaction is securely recorded, ensuring an immutable history of ownership and authenticity. Also we give the owner of the NFT real ownership op the Real Estate</p>
        </div>
        <div className="col-md-6">
          <img src="/rt.png" alt="Real Estate Illustration" style={{ width: '100%', height: 'auto' }} />
       </div>
    </div>
    </section>
    <section className="bg-light mt-5 p-5">
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
      {sortedProducts.slice(0, 3).map((product) => (
        <div key={product.id} className="col mb-3">
          <div className="card" style={{ height: '100%' }}>
            <img
              src={product.imageUrl}
              className="card-img-top"
              alt={product.name}
              style={{ height: '200px', objectFit: 'cover' }}
            />
            <div className="card-body">
              <h5 className="card-title">{product.name}</h5>
              <p className="card-text">{product.price} $</p>
              <div className="d-flex justify-content-between align-items-center">
                {product.availableUnits === 0 ? (
                  <div>
                    <p>Mint done</p>
                    <button onClick={() => gotoShop(product.id)} className="btn btn-primary">
                      Buy on marketplace
                    </button>
                  </div>
                ) : (
                  <div>
                    <p>Ongoing Mint</p>
                    <button onClick={() => redirectToDetails(product.id)} className="btn btn-primary">
                      Mint details
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    <center>
    <button className="btn btn-secondary" onClick={showMoreProperties}>
      Show all properties
    </button>
    </center>
  </section>

      <section className="bg-light mt-5 p-5">
        <div className="container">
          <h2>How It Works</h2>
          <div className="row">
            <div className="col-md-4">
              <h4>1. Mint Your Property</h4>
              <p>
                Easily mint your virtual real estate property as an NFT. Define its characteristics, location, and unique features.
              </p>
            </div>
            <div className="col-md-4">
              <h4>2. Trade on the Marketplace</h4>
              <p>
                List your property on our marketplace. Connect with buyers and sellers to trade properties in a decentralized environment.
              </p>
            </div>
            <div className="col-md-4">
              <h4>3. Ownership on the Blockchain</h4>
              <p>
                Benefit from secure and transparent ownership records on the blockchain. Verify property ownership with ease.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container mt-5">
        <h2>Get Started</h2>
        <p>
          Join the BlockEstateRealty revolution today. Mint your property, explore the marketplace, and experience a new era of virtual property ownership.
        </p>
        <button className="btn btn-primary" onClick={handleOnclick}>Start Minting</button>
      </section>
    <section className="bg-light mt-5 p-5">
    <h3> Important disclaimer (this company doesn't exist is just a design example)</h3>

    <p>BlockEstateRealty, operates as the manager overseeing BlockEstate Homes, LLC, BlockEstate STR, LLC, BlockEstate Homes 3, LLC, and BlockEstate STR 2, LLC and manages the blockestate.com website. It's essential to note that BlockEstateRealty is not a broker-dealer or investment advisor. All activities related to securities are conducted exclusively through main Holding, a registered broker-dealer and member of FINRA/SIPC, situated at 123 Main Street, Suite 456, Anytown, USA 54321. You are encouraged to review Holdings brokercheck, and an up-to-date Holding Form CRS is available for your reference. When considering any offering, it is advisable to consult with your financial advisor, accountant, and/or attorney. It's crucial to understand that neither BlockEstateRealty nor any of the BlockEstateRealty Issuers, nor Holding, provides recommendations or advice regarding investments. Any communication through this website or other mediums should not be construed as a recommendation for any security offered, whether on or off this investment platform.
      The Site may contain forward-looking statements, and it is crucial not to solely rely on these statements. Careful evaluation of the offering materials is necessary to assess any investment opportunity, including a thorough examination of the comprehensive set of risk factors provided as part of the offering circular. The BlockEstateRealty Issuers conduct public offerings in accordance with Regulation A, as amended, through the Site. You can find the offering circulars and periodic reports for each of the BlockEstateRealty Issuers on our Filings Page. It is imperative to understand that past performance does not guarantee future results. Investments on the BlockEstate platform are speculative and come with significant risks, as outlined in the respective offering materials. These risks include, but are not limited to, illiquidity, lack of diversification, and the potential for complete loss of capital.

      Key risks to consider encompass factors such as limited operating history, limited diversification, the risk of asset damage or theft, and the absence of voting rights. Additionally, the adverse economic effects of the COVID-19 pandemic remain unknown and could materially impact this investment. Investing in an offering constitutes an investment solely in a particular series and not in any of the BlockEstateRealty Issuers or the underlying asset(s). Investors are strongly encouraged to carefully review the risks outlined in the respective offering materials for a comprehensive discussion of risk.

      From time to time, the BlockEstateRealty Issuers will seek to qualify additional series offerings of securities under Regulation A. For offerings that have not yet been qualified, no money or other consideration is being solicited, and if sent in response, will not be accepted. It's crucial to note that no offer to buy securities of a particular offering can be accepted, and no part of the purchase price can be received until an offering statement filed with the Securities and Exchange Commission (the "SEC") relating to that series has been qualified by the SEC. Any such offer may be withdrawn or revoked, without obligation or commitment of any kind, at any time before notice of acceptance given after the date of qualification by the SEC. An indication of interest involves no obligation or commitment of any kind.

      The investment overviews contained herein are summaries of the purpose and the principal business terms of the investment opportunities. These summaries are for informational purposes only and do not claim to be complete. Each summary is qualified in its entirety by reference to the more detailed discussions contained in the respective offering circular filed with the SEC. None of the BlockEstateRealty Issuers offer refunds after an investment has been made. For more information, it is recommended to review the relevant offering materials and subscription documentation.

      It is important to acknowledge that an active trading market for any series of any of the BlockEstateRealty Issuers' membership interests may not develop or be sustained. If an active public trading market for such series interests does not develop or is not sustained, it may be challenging or impossible to resell interests at any price. Even if an active market does develop, the market price could decline below the amount paid for the interests. There is no assurance that the BlockEstate platform will provide an active market for resales of such series interests. Furthermore, without the BlockEstate platform, disposing of interests may be challenging or impossible.

      Should a market develop for any series of interests offered on the BlockEstate Platform, the market price of such interests could fluctuate significantly for various reasons, including factors unrelated to performance, the underlying assets, or any series. These factors may include reports by industry analysts, investor perceptions, announcements by competitors regarding their own performance, and general economic and industry conditions. For additional information on risk factors and disclaimers, please refer to our communications disclaimer.</p>

     </section>
    </div>
  );
}

export default HomePage;
import React from 'react';
import AccountDetailsPage from './AccountDetailPage';

const UserProfile = () => {
  // Replace these with actual user data
  const username = 'john_doe';
  const email = 'john@example.com';
  const isKycDone = false;
  
  const test = true;
  if (test){
    return (
      <p>comming soon ...</p>
    );
  
  }

  return (
    <div>
      {/* Other components or content */}
      <AccountDetailsPage username={username} email={email} isKycDone={isKycDone} />
    </div>
  );
};

export default UserProfile;

import React from 'react';
import './ConnectWalletButton.css';

function ConnectWalletButton() {

  return (
    <div className="ManifoldConnect">
      <div
        data-widget="m-connect"
        data-grant-type="signature"
        data-client-id="5ca0b5eb33e7da69f23457805edab4d9c21ce457d34ace4d84cf6e1fa4bfec87"
        data-app-name="Test realestate"
        data-network="5" //görli testnet
      >
      </div>
    </div>
  );
}

export default ConnectWalletButton;

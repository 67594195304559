// KYCPage.js
import React, { useState } from 'react';

function KYCPage() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    address: '',
    identificationType: '',
    identificationNumber: '',
    nationality: '',
    gender: '',
    email: '',
    phone: '',
    occupation: '',
    employer: '',
    sourceOfFunds: '',
    consent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle the submission of KYC data
    console.log('KYC Form submitted:', formData);
  };

  return (
    <div className="container mt-5">
      <h2>KYC Form</h2>
      <p>FYI you only need KYC if you take part in the product mint because we use fiat to raise the funds otherwise you can just connect your wallet and buy and sell NFTs with crypto</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">
            First Name
          </label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="lastName" className="form-label">
            Last Name
          </label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="dateOfBirth" className="form-label">
            Date of Birth
          </label>
          <input
            type="date"
            className="form-control"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="address" className="form-label">
            Address
          </label>
          <textarea
            className="form-control"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="identificationType" className="form-label">
            Identification Type
          </label>
          <input
            type="text"
            className="form-control"
            id="identificationType"
            name="identificationType"
            value={formData.identificationType}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="identificationNumber" className="form-label">
            Identification Number
          </label>
          <input
            type="text"
            className="form-control"
            id="identificationNumber"
            name="identificationNumber"
            value={formData.identificationNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="nationality" className="form-label">
            Nationality
          </label>
          <input
            type="text"
            className="form-control"
            id="nationality"
            name="nationality"
            value={formData.nationality}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="gender" className="form-label">
            Gender
          </label>
          <select
            className="form-select"
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="phone" className="form-label">
            Phone Number
          </label>
          <input
            type="tel"
            className="form-control"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="occupation" className="form-label">
            Occupation
          </label>
          <input
            type="text"
            className="form-control"
            id="occupation"
            name="occupation"
            value={formData.occupation}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="employer" className="form-label">
            Employer
          </label>
          <input
            type="text"
            className="form-control"
            id="employer"
            name="employer"
            value={formData.employer}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="sourceOfFunds" className="form-label">
            Source of Funds
          </label>
          <textarea
            className="form-control"
            id="sourceOfFunds"
            name="sourceOfFunds"
            value={formData.sourceOfFunds}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="consent"
            name="consent"
            checked={formData.consent}
            onChange={handleChange}
            required
          />
          <label className="form-check-label" htmlFor="consent">
            I consent to the collection, processing, and storage of my personal information for KYC purposes.
          </label>
        </div>

        <button type="submit" className="btn btn-primary">
          Submit KYC
        </button>
      </form>
    </div>
  );
}

export default KYCPage;
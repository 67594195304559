import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './Containers/Home/HomePage';
import MintPage from './Containers/Mint/MintPage';
import ShopPage from './Containers/shop/ShopPage';
import CheckoutPage from './Containers/shop/CheckoutPage';
import PropertyDetail from './Containers/shop/PropertyDetail';
import LockAndEarnPage from './Containers/PropertyLock/LockAndEarnPage';
import KYCPage from './Containers/Kyc/KycPage';
import LoginModal from './Containers/Login/LoginModal'; // Import your LoginModal component
import RegistrationForm from './Containers/Login/RegistrationForm';
import UserProfile from './Containers/Profile/UserProfile';
import ConnectWalletButton from './Containers/Mint/ConnectWalletButton';
import PageContent from './Containers/WordPress/PageContent';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // This includes Bootstrap JS and Popper.js

function App() {
  const [isWalletConnected, setWalletConnected] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };
  
  const closeLoginModal = () => {
    setLoginModalOpen(false);
  };
  
  const handleLogin = () => {
    // Handle login logic here
    // You can also close the modal after successful login
    closeLoginModal();
  };

  const handleConnectWallet = () => {
    // Add your logic to connect the wallet
    // For now, let's toggle the wallet connection state
    setWalletConnected(!isWalletConnected);
  };

  const handleDisconnectWallet = () => {
    // Add your logic to disconnect the wallet
    // For now, let's set the wallet connection state to false
    setWalletConnected(false);
  };
  
  return (
    <Router>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link to="/" className="navbar-brand">
            NFT Real Estate
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/mint" className="nav-link">
                  Mint
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/lockAndEarn" className="nav-link">
                  Rent
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/shop" className="nav-link">
                  Shop
                </Link>
              </li>
              </ul>
              <ul className="navbar-nav ms-auto">

            <li className="nav-item">

              {/* Connect Wallet Button */}
              <button 
                className="btn btn-outline-primary" 
                style={{ margin: '4px' }}
                onClick={openLoginModal}
              >
                Login
              </button>
            </li>
            <li className="nav-item">
              <Link to="/profile" className="btn btn-outline-primary" style={{ margin: '4px' }}>
                User
              </Link>
            </li>
            <li className="nav-item">
              {isLoginModalOpen && (
                <LoginModal
                onClose={closeLoginModal} // Pass a callback to close the modal
                onLogin={handleLogin} // Pass a callback for login logic
                />
              )}
            </li>
            <li className="nav-item">
              <ConnectWalletButton />
            </li>
          </ul>
        </div>
      </div>
    </nav>

      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/mint" element={<MintPage />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/buy" element={<CheckoutPage />} />
          <Route path="/kyc" element={<KYCPage />} />
          <Route path="/property/:propertyId" element={<PropertyDetail />} />
          <Route path="/lockAndEarn" element={<LockAndEarnPage />} />
          <Route path="/wordpress" element={<PageContent pageId={4785} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ShopPage() {
  const [products, setProducts] = useState([
    { id: 1, name: 'Property 1', price: 5, imageUrl: '/0.png' },
    { id: 2, name: 'Property 2', price: 3, imageUrl: '/rt.png' },
    { id: 3, name: 'Property 3', price: 2, imageUrl: '/0.png' },
    { id: 4, name: 'Property 4', price: 3, imageUrl: '/rt.png' },
  ]);

  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();

  const addToCart = (product) => {
    const itemIndex = cartItems.findIndex((item) => item.id === product.id);

    if (itemIndex !== -1) {
      // Item is already in the cart, remove it
      const updatedCartItems = [...cartItems];
      updatedCartItems.splice(itemIndex, 1);
      setCartItems(updatedCartItems);
    } else {
      // Item is not in the cart, add it
      setCartItems([...cartItems, product]);
    }
  };

  const handleBookNow = () => {
    console.log('Book Now clicked!');
    console.log('Cart Items:', cartItems);
    navigate('/buy', { state: { cartItems: cartItems } });
  };
  
  // Function to navigate to property details
const redirectToDetails = (productId) => {
  navigate(`/property/${productId}`);
};

const test = true;
if (test){
  return (
    <p>comming soon ...</p>
  );

}

  return (
    <div className="container">
      <h1 className="mb-4">Online Shop</h1>
      <p>you can also buy and sell on secondary plattforms like opensea:</p><a className='btn btn-primary' href='https://testnets.opensea.io/collection/realestate-test'>Buy on opensea</a>
      <h2>Products</h2>
      <div className="row">
        {products.map((product) => (
          <div key={product.id} className="col-md-6 mb-3">
            <div className="card">
              <img
                src={product.imageUrl}
                className="card-img-top"
                alt={product.name}
                style={{ maxHeight: '300px', objectFit: 'cover' }}
              />
              <div className="card-body">
                <h5 className="card-title">{product.name}</h5>
                <p className="card-text">{product.price} ETH</p>
                {/* "Add to Cart" Button */}
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className={`btn btn-${cartItems.some((item) => item.id === product.id) ? 'danger' : 'primary'}`}
                    onClick={() => addToCart(product)}
                  >
                    {cartItems.some((item) => item.id === product.id) ? 'Remove from Cart' : 'Add to Cart'}
                  </button>
                  {/* Button to redirect to property details */}
                  <button className="btn btn-primary" onClick={() => redirectToDetails(product.id)}>
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <h2>Cart</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <div>
          {cartItems.map((item) => (
            <div key={item.id} className="card mb-3">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <b>{item.name}</b> ({item.price} ETH)
                </div>
                <button className="btn btn-danger" onClick={() => addToCart(item)}>
                  Remove
                </button>
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-between align-items-center">
            <p className="font-weight-bold">
              Total: {cartItems.reduce((total, item) => total + item.price, 0)} ETH
            </p>
            <button className="btn btn-primary mt-3" onClick={handleBookNow}>
              Book Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShopPage;
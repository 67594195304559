import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MintPage = () => {
  const [mints, setMints] = useState([
    {
      id: 1,
      name: 'Virtual Property 1',
      imageUrl: '/rt.png',
      price: 1,
      availableUnits: 5,
    },
    {
      id: 2,
      name: 'Virtual Property 2',
      imageUrl: '/rt.png',
      price: 1,
      availableUnits: 0,
    },
    {
      id: 3,
      name: 'Virtual Property 3',
      imageUrl: '/realestade_test.png',
      price: 1,
      availableUnits: 2,
    },
  ]);

  const navigate = useNavigate();

  const handleMint = (mintId) => {
    // Implement minting logic here when a wallet is connected
    console.log(`Minting NFT with ID ${mintId}`);
  };

  const gotoShop = (mintId) => {
    navigate('/shop', mintId);
  };
  
  const test = true;
  if (test){
    return (
      <p>comming soon ...</p>
    );
  
  }

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Mint Page</h2>

      <div className="row">
        {mints.map((mint) => (
          <div key={mint.id} className="col-md-4 mb-3">
            <div className="card">
              <img src={mint.imageUrl} className="card-img-top" alt={mint.name} style={{ height: '200px', objectFit: 'cover' }} />
              <div className="card-body">
                <h5 className="card-title">{mint.name}</h5>
                <p className="card-text">{`Price: ${mint.price} ETH`}</p>
                {mint.availableUnits === 0 ? (
                  <div>
                    <p>Mint done</p>
                    <button onClick={() => gotoShop(mint.id)} className="btn btn-primary">
                      Buy on marketplace
                    </button>
                  </div>
                ) : (
                  <div>
                    <p>Ongoing Mint</p>
                    <button onClick={() => handleMint(mint.id)} className="btn btn-primary">
                      Mint
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MintPage;

import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import '../../App.css';

function CheckoutPage() {
  const location = useLocation();
  const { cartItems } = location.state;
  console.log(cartItems);
  const [paymentMethod, setPaymentMethod] = useState('paypal');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: totalSum.toString(),
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      console.log("Transaction completed by " + details.payer.name.given_name);
      // Perform any necessary actions after successful payment
    });
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  };

  const handleExpiryDateChange = (event) => {
    setExpiryDate(event.target.value);
  };

  const handleCvvChange = (event) => {
    setCvv(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Perform payment processing logic based on selected payment method and entered card details
    if (paymentMethod === 'paypal') {
      // Process payment with PayPal
    } else if (paymentMethod === 'creditcard') {
      // Process payment with credit card details
    }
  };

  // Calculate the total sum of all items
  const totalSum = cartItems.reduce((sum, item) => sum + item.price, 0);

  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
        <h5 className="card-title">Payment Details</h5>
          <div className="mb-3">
            <h3>Selected Items</h3>
            <ul className="list-group">
              {cartItems.map((item) => (
                <li key={item.id} className="list-group-item">
                  {item.name} - {item.price} ETH
                </li>
              ))}
            </ul>
              <h6 style={{ marginTop: '20px' }} id="totalSum">Total: {totalSum} ETH</h6>
              <button type="submit" className="btn btn-primary">
                checkout
              </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutPage;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function PropertyDetail() {
  const { propertyId } = useParams();
  const [property, setProperty] = useState(null);

  useEffect(() => {
    // For now, let's hardcode some property details
    const hardcodedProperties = {
      1: { id: 1, name: 'Property 1', price: 5, imageUrl: '/0.png', description: 'Description: The property has 200 squarefeet and a balkony...', location: 'Schulstraße 1, 55555 Buxdehude, Germany' },
      2: { id: 2, name: 'Property 2', price: 3, imageUrl: '/rt.png', description: 'Description: The property has 200 squarefeet and a balkony...', location: 'Blumenheck 1, 55555 Buxdehude, Germany' },
      3: { id: 3, name: 'Property 3', price: 2, imageUrl: '/0.png', description: 'Description: The property has 200 squarefeet and a balkony...', location: 'Blumenheck 10, 55555 Buxdehude, Germany' },
      4: { id: 4, name: 'Property 2', price: 3, imageUrl: '/rt.png', description: 'Description: The property has 200 squarefeet and a balkony...', location: 'Blumenheck 1, 55555 Buxdehude, Germany' },
    };

    setProperty(hardcodedProperties[propertyId]);
  }, [propertyId]);

  if (!property) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container">
      <h1 className="mb-4">{property.name} Details</h1>
        <img
          src={property.imageUrl}
          className="card-img-top"
          alt={property.name}
          style={{ maxHeight: '600px', objectFit: 'cover' }}
        />
        <div className="card-body">
          <h5 className="card-title">{property.name}</h5>
          <p className="card-text">{property.location}</p>
          <p className="card-text">{property.price} ETH</p>
          <p className="card-text">{property.description}</p>
        </div>
    </div>
  );
}

export default PropertyDetail;
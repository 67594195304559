import React, { useState } from 'react';
import Modal from 'react-modal';

const LockAndEarnPage = () => {
  const [nfts, setNfts] = useState([
    { id: 1, name: 'NFT 1', imageUrl: '/rt.png', unlockDays: 7, dateWhenLocked: null },
    { id: 2, name: 'NFT 2', imageUrl: '/realestade_test.png', unlockDays: 30, dateWhenLocked: null },
    // Add more NFTs as needed
  ]);

  const [lockedNfts, setLockedNfts] = useState([]);
  const [selectedNft, setSelectedNft] = useState(null);
  const [lockDays, setLockDays] = useState(7);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const lockNft = (nftId) => {
    const selectedNft = nfts.find((nft) => nft.id === nftId);
    setSelectedNft(selectedNft);
    setIsModalOpen(true);
  };

  const unlockNft = (lockedNftId) => {
    const selectedLockedNft = lockedNfts.find((lockedNft) => lockedNft.id === lockedNftId);

    // Check if the locking period is over
    const daysLocked = Math.floor((new Date() - new Date(selectedLockedNft.dateWhenLocked)) / (1000 * 60 * 60 * 24));

    if (daysLocked >= selectedLockedNft.unlockDays) {
      setNfts([...nfts, { ...selectedLockedNft, dateWhenLocked: null }]);
      setLockedNfts(lockedNfts.filter((lockedNft) => lockedNft.id !== lockedNftId));
    } else {
      alert(`Cannot unlock. Days locked: ${daysLocked}, Unlock after: ${selectedLockedNft.unlockDays} days.`);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedNft(null);
    setLockDays(7); // Reset the lock days for the next selection
  };

  const handleLockConfirm = () => {
    if (selectedNft) {
      if (lockDays > 1500) {
        alert("Maximum lock period is 1500 days.");
        return;
      }
  
      const updatedNfts = nfts.filter((nft) => nft.id !== selectedNft.id);
      selectedNft.unlockDays = lockDays;
      selectedNft.dateWhenLocked = new Date();
      setLockedNfts([...lockedNfts, selectedNft]);
      setNfts(updatedNfts);
      handleModalClose();
    }
  };
  
  const test = true;
  if (test){
    return (
      <p>comming soon ...</p>
    );
  
  }


  return (
    <div className="container">
      <section className="nft-locking">
        <h2>NFT Locking</h2>
        <div className="row">
          {nfts.map((nft) => (
            <div key={nft.id} className="col-md-6 mb-3">
              <div className="card">
                <img
                  src={nft.imageUrl}
                  alt={nft.name}
                  className="card-img-top"
                  style={{ maxHeight: '300px', objectFit: 'cover' }}
                />
                <p>{nft.name}</p>
                <button className="btn btn-primary" onClick={() => lockNft(nft.id)}>
                  Lock
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="locked-nfts">
        <h2>Locked NFTs</h2>
        <div className="row">
          {lockedNfts.map((lockedNft) => (
            <div key={lockedNft.id} className="col-md-6 mb-3">
              <div className="card">
                <img
                  src={lockedNft.imageUrl}
                  alt={lockedNft.name}
                  className="card-img-top"
                  style={{ maxHeight: '300px', objectFit: 'cover' }}
                />
                <p>{lockedNft.name}</p>
                <p>Unlock Days: {lockedNft.unlockDays}</p>
                <p>Date Locked: {lockedNft.dateWhenLocked.toLocaleDateString()}</p>
                <button
                  className="btn btn-primary"
                  onClick={() => unlockNft(lockedNft.id)}
                  disabled={lockedNft.dateWhenLocked !== null}
                >
                  Unlock
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Lock Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <h2>Choose Lock Period</h2>
        <label>
          Lock Period (Days):
          <input
            type="number"
            min="1"
            value={lockDays}
            onChange={(e) => setLockDays(parseInt(e.target.value, 10))}
          />
        </label>
        <br />
        <br />
        <br />
        <button className="btn btn-primary" onClick={handleLockConfirm}>Confirm Lock</button>&nbsp;&nbsp;
        <button className="btn btn-primary" onClick={handleModalClose}>Cancel</button>
      </Modal>
    </div>
  );
};

export default LockAndEarnPage;